import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { React, useMemo, useState } from 'react';
import { HEADER_MIN_HEIGHT } from '../appTheme';
import { useCurrentHotel, usePreloadHotel } from '../hooks/currentHotel';
import HotelSelect from '../../core/components/Select/HotelSelect';
import {
  useCurrentUser,
  useOnUserDisconnection,
} from '../hooks/authenticationStatus';
import { useRouter } from 'next/router';
import useApiClient from '../hooks/apiClient';

export default function Header(props) {
  const hotel = useCurrentHotel();
  const user = useCurrentUser();
  const router = useRouter();
  const apiClient = useApiClient();
  const onUserDisconnection = useOnUserDisconnection();

  const hotelSelectValue = useMemo(() => {
    return {
      id: hotel?.id,
      label: hotel?.name,
    };
  }, [hotel]);

  const preloadHotel = usePreloadHotel();

  return (
    <Flex
      id="header"
      ref={props.header}
      background={'light'}
      px={4}
      minHeight={HEADER_MIN_HEIGHT}
      alignItems="center"
      borderBottomWidth={1}
      justifyContent="flex-end"
      pos="sticky"
      top={0}
      zIndex={2}
    >
      <Heading
        mr="auto"
        title={props.pageTitle}
        color="primaryLighter"
        fontSize="3xl"
        noOfLines={1}
      >
        {props.pageTitle}
      </Heading>
      <Box mx={4}>
        {hotel && (
          <HotelSelect
            mb={0}
            size="xs"
            label={null}
            allowClear={false}
            id="hotelSelector"
            selectedItem={hotelSelectValue}
            includes={['hasLibeoAPIConnexion', 'hasExthandActivated']}
            onChange={(selectedValue) => {
              preloadHotel(selectedValue.item);

              router.push(
                router.asPath.replace(
                  '/' + hotel.slug,
                  '/' + selectedValue.item.slug
                )
              );
            }}
          />
        )}
      </Box>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} variant="unstyled">
          <Flex
            as="span"
            display="flex"
            alignItems="center"
            px={2}
            whiteSpace="nowrap"
          >
            <Avatar bg="primaryLighter" mr={1} size="xs" />
            {user.firstName} {user.lastName}
          </Flex>
        </MenuButton>
        <MenuList zIndex={1000}>
          <MenuItem
            onClick={async () => {
              await apiClient('/api/logout');

              onUserDisconnection();
            }}
          >
            Déconnexion
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
